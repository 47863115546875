<template>
  <div class="login-page">
    <div class="main-wrap">
      <div class="top-wrap">
        <div class="title">WEB</div>
        <div class="sub-title"></div>
      </div>
      <div class="form-wrap">
        <Form class="form-out" :label-width="0">
<!--          <FormItem>-->
<!--            <Input prefix="md-home" v-model="hotelCode" @on-blur="getConnectUrl" class="form-input upper"-->
<!--                   placeholder="酒店代码"/>-->
<!--          </FormItem>-->
          <FormItem>
            <Input prefix="md-person" v-model="userName" class="form-input upper" placeholder="用户名"/>
          </FormItem>
          <FormItem>
            <Input prefix="md-lock" v-model="password" class="form-input" type="password" placeholder="密码"/>
          </FormItem>
          <div class="login-btn" @click="doLogin">登录</div>
<!--          <div class="connect-btn" @click="showModal = true">连接设置</div>-->
        </Form>
      </div>

      <Modal
          v-model="showModal"
          title="连接地址配置">
        <div style="height: 120px;padding-top: 20px;box-sizing: border-box">
          <Form :label-width="80">
            <FormItem label="连接地址：">
              <Input type="text" v-model="connectUrl"/>
            </FormItem>
          </Form>
        </div>
        <div slot="footer">
          <Button @click="cancel()">取消</Button>
          <Button type="primary" @click="confirmConnect()">确定</Button>
        </div>
      </Modal>
    </div>

    <div class="env">{{ $env }}</div>
  </div>
</template>
<script>
export default {
  name: 'Login',
  components: {},
  data() {
    return {
      hotelCode: localStorage.getItem('hotelCode') || '',
      userName: localStorage.getItem('userName') || '',
      password: '', // '81dc9bdb52d04dc20036dbd8313ed055',
      magicNo: '38524fc8-e819-4ef7-be7e-15f327d5d72d',

      // 连接地址
      showModal: false,
      connectUrl: localStorage.getItem('connectUrl') || '',
    }
  },
  mounted() {
    localStorage.removeItem('token')

    if (this.$env == 'development') {
      this.hotelCode = 'GCBZ'
      this.userName = 'ADMIN'
      this.password = '1234' // '81dc9bdb52d04dc20036dbd8313ed055',
      localStorage.setItem('connectUrl', '')
    }
  },
  methods: {
    getConnectUrl() {
      var url = "https://api.ihotel.cn/s/url/" + this.hotelCode.toUpperCase() + "_POS";
      this.AXIOS.get(url, {timeout: 10000, noUrl: 'yes'}).then(res => {
        if (res.code == 0) {
          var obj = res.data[0] || {};
          var connectUrl = obj.url || ''
          this.connectUrl = connectUrl
          localStorage.setItem('connectUrl', connectUrl)
        } else {
          this.$Message.warning('没有查到连接地址，请手动配置');
        }
      });
    },
    cancel() {
      this.showModal = false
    },
    confirmConnect() {
      if (this.connectUrl) {
        localStorage.setItem('connectUrl', this.connectUrl)
        this.showModal = false
      } else {
        this.$Message.warning('请输入连接地址');
      }
    },
    test() {
      this.$router.push('/ReturnMoney/ReturnMoneyList')
    },
    validForm() {
      var flag = true
      if (!this.hotelCode) {
        this.$Message.warning('请输入酒店代码');
        flag = false
      }
      if (!this.userName) {
        this.$Message.warning('请输入用户名');
        flag = false
      }
      if (!this.password) {
        this.$Message.warning('请输入密码');
        flag = false
      }
      return flag
    },
    doLogin() {
      var self = this
      this.$router.push('/Dashboard')
      // if (this.validForm()) {
      //   this.AXIOS.post('/pos/router', {
      //     method: 'loginApp',
      //     hotelCode: this.hotelCode.toUpperCase(),
      //     userName: this.userName.toUpperCase(),
      //     password: this.$md5(this.password),
      //     magicNo: this.magicNo,
      //   }).then((res) => {
      //     localStorage.setItem('hotelCode', this.hotelCode.toUpperCase())
      //     localStorage.setItem('userName', this.userName.toUpperCase())
      //     localStorage.setItem('token', res || '')
      //     this.getAuth()
      //   })
      // }
    },
    getAuth() {
      const self = this
      this.AXIOS.post('/pos/router?method=getGBAuths', {
        method: 'getGBAuths',
        v: '1.0',
      }).then((res) => {
        // "kdsView", 厨打处理
        // "snackInvoiceOpen", 开票管理
        // "snackAccessSet", 沽清设置
        // "snackRefundOrder"  退款
        // "snackDetailView"  订单查询
        // "snackReportView"  数据中心
        if (res && res.length) {
          var url = ''
          if (res.indexOf('snackReportView') > -1) { // 数据中心
            url = '/DataReport/DataReport'
          }
          if (res.indexOf('snackAccessSet') > -1) {
            url = '/Estimating/EstimatingList'
          }
          if (res.indexOf('snackInvoiceOpen') > -1) {
            url = '/OpenReceipt/OpenReceiptList'
          }
          if (res.indexOf('snackRefundOrder') > -1) {
            url = '/ReturnMoney/ReturnMoneyList'
          }
          if (res.indexOf('snackDetailView') > -1) {
            url = '/Order/OrderList'
          }
          if (res.indexOf('kdsView') > -1) {
            url = '/KitchenPrint/KitchenPrint'
          }
          this.$router.push(url)
        } else {
          this.$Message.warning('当前用户没有任何菜单权限，请联系管理员添加');
        }
      }).finally((res) => {
      })
    },
  }
}
</script>
<style lang="less">
.login-page {
  height: 100%;
  background: url("../assets/images/star.png") 100% 100%;
  background-size: cover;
  padding-top: 70px;
  text-align: center;

  .env {
    position: fixed;
    right: 15px;
    bottom: 15px;
    color: #fff;
  }

  .connect-btn {
    color: #666;
    margin-top: 20px;
    font-size: 14px;
    text-align: right;
    cursor: pointer;
  }

  .upper .ivu-input {
    text-transform: uppercase;
  }

  .main-wrap {
    background: #fff;
    border-radius: 10px;
    padding: 60px 150px 80px;
    width: 800px;
    box-sizing: border-box;
    display: inline-block;
    margin: 0 auto;
    margin-top: 100px;
    .top-wrap {
      text-align: center;
      padding-bottom: 40px;
      box-sizing: border-box;

      .title {
        font-size: 36px;
        color: #112642;
      }

      .sub-title {
        font-size: 20px;
        color: #112642;
      }
    }

    .form-wrap {
      text-align: center;
      box-sizing: border-box;
      padding: 0 30px;

      .form-out {

        box-sizing: border-box;
        margin: 0 auto;
        max-width: 550px;

        .form-input {
          .ivu-input {
            height: 50px;
            font-size: 20px;
            line-height: 50px;
            padding-left: 45px;
            box-sizing: border-box;
            border: none;
            border-bottom: 1px solid #ccc;
            border-radius: 0;
            outline: none;

            &:focus, &:active {
              border: none;
              outline: none;

            }
          }

          .ivu-input-prefix {
            height: 50px;
            line-height: 50px;

            i {
              font-size: 26px;

              line-height: 50px;
            }
          }

        }

        .login-btn {
          background-image: linear-gradient(141deg, #008FFF 0%, #3766F4  100%);
          border-radius: 2.75px;
          color: #fff;
          width: 100%;
          height: 60px;
          line-height: 60px;
          text-align: center;
          margin-top: 40px;
          font-size: 20px;

          &:hover {
            cursor: pointer;
            background: #3766F4;
          }

          &:active {
            cursor: pointer;
            background: #3766F4;
          }
        }
      }

    }
  }
}
</style>
